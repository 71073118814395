import * as React from "react"
import styled from "styled-components"
import { breakpoint } from "styled-components-breakpoint";

const ButtonContainer = styled.div`
    display: block;
    ${breakpoint("tablet") `
        display: none;
    `}

    position: fixed;
    width: 80px;
    height: 80px;

    border-radius: 100%;
    background-color: #577590;

    top: 30px;
    right: 30px;

    z-index: 999;

    transition: .5s;
    opacity: 0.8;

    &:hover, &:focus{
        transform: scale(1.2);
        box-shadow: 0px 0px 10px 0px #333;
    }

    & div{
        width: 100%;
        height: 100%;
    }

    & div span{
    display: block;
    position: absolute;
    height: 10px;
    width: 100%;
    background: #Fdfdfd;
    border-radius: 8px;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .25s ease-in-out;
    -moz-transition: .25s ease-in-out;
    -o-transition: .25s ease-in-out;
    transition: .25s ease-in-out;

    transform: scale(0.7);
    }

    & div span:nth-child(1) {
        top:18px;
    }
    
    & div span:nth-child(2) {
        top: 36px;
    }
    
    & div span:nth-child(3) {
        top: 54px;
    }
`


const HamburgerButton = (props) => {
    return (
        <ButtonContainer  id="hamburger-button">
            <div role="button" onClick={props.handle}>
                <span></span>
                <span></span>
                <span></span>
            </div>
        </ButtonContainer>
  )
}

export default HamburgerButton;
