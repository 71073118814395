// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-formularz-js": () => import("./../../../src/pages/formularz.js" /* webpackChunkName: "component---src-pages-formularz-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-ksiega-pamieci-js": () => import("./../../../src/pages/ksiega-pamieci.js" /* webpackChunkName: "component---src-pages-ksiega-pamieci-js" */),
  "component---src-pages-o-pochowku-js": () => import("./../../../src/pages/o-pochowku.js" /* webpackChunkName: "component---src-pages-o-pochowku-js" */),
  "component---src-pages-o-pomniku-js": () => import("./../../../src/pages/o-pomniku.js" /* webpackChunkName: "component---src-pages-o-pomniku-js" */),
  "component---src-pages-policy-js": () => import("./../../../src/pages/policy.js" /* webpackChunkName: "component---src-pages-policy-js" */),
  "component---src-pages-pomoc-js": () => import("./../../../src/pages/pomoc.js" /* webpackChunkName: "component---src-pages-pomoc-js" */),
  "component---src-templates-article-js": () => import("./../../../src/templates/article.js" /* webpackChunkName: "component---src-templates-article-js" */)
}

